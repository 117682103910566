<template>
  <div>
    <b-card title="Ekle">
      <b-row>
        <b-col cols="12">
          <tckn />
        </b-col>
        <b-col><name /></b-col>
        <b-col><surname /></b-col>
        <b-col cols="12">
          <person-title />
        </b-col>
        <b-col cols="12">
          <birthday />
        </b-col>
        <b-col>
          <start-date />
        </b-col>
        <b-col>
          <end-date />
        </b-col>
        <b-col cols="12">
          <management />
        </b-col>
        <b-col cols="12">
          <summary-resume />
        </b-col>
        <b-col cols="12">
          <detail-resume />
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Fotoğraf">
      <upload-image />
    </b-card>
    <save-button :action-methods="submitForm" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import Tckn from '@/views/Hr/Employees/forms/Tckn.vue'
import Name from '@/views/Hr/Employees/forms/Name.vue'
import Surname from '@/views/Hr/Employees/forms/Surname.vue'
import PersonTitle from '@/views/Hr/Employees/forms/Title.vue'
import SummaryResume from '@/views/Hr/Employees/forms/Summary_resume.vue'
import Birthday from '@/views/Hr/Employees/forms/Birthday.vue'
import Management from '@/views/Hr/Employees/forms/Management.vue'
import StartDate from '@/views/Hr/Employees/forms/Start_date.vue'
import EndDate from '@/views/Hr/Employees/forms/End_date.vue'
import DetailResume from '@/views/Hr/Employees/forms/Resume.vue'
import UploadImage from '@/views/Hr/Employees/forms/UploadImage.vue'
import SaveButton from '@/views/Hr/Employees/forms/SaveButton.vue'

export default {
  name: 'Add',
  components: {
    BRow,
    BCol,
    BCard,
    Tckn,
    Name,
    Surname,
    PersonTitle,
    SummaryResume,
    Birthday,
    StartDate,
    Management,
    EndDate,
    DetailResume,
    UploadImage,
    SaveButton,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        title: null,
      },
    }
  },
  computed: {
    employee() {
      return this.$store.getters['hrEmployees/getHr_employee']
    },
    saveStatus() {
      return this.$store.getters['hrEmployees/getHr_employeeSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.employee.submitStatus = false
    },
  },
  created() {
    this.$store.dispatch('hrEmployees/hr_employeeReset')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.employee.submitStatus = true
      this.$store.dispatch('hrEmployees/hr_employeeSave', this.employee)
    },
  },
}
</script>
